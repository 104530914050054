import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Buffalo Style Plant Based Bites</title>
          <meta
            name="description"
            content="Our Raised & Rooted™ Buffalo Style Plant Based Bites are delicious!  Made with plant based protein, pick up a bag of these meatless buffalo bites today!"
          />
          <meta itemProp="name" content="Buffalo Style Plant Based Bites" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Buffalo Style Plant Based Bites"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Give your taste buds a kick. Raised & Rooted™ Buffalo Style Plant Based Bites are made with 100% plant based protein and seasoned with cayenne pepper. They’re so tasty, you’ll forget they’re made with plants."
              itemColor="#006B2D"
              valueText1="8"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="4"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="33"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made With Plant Based Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data for White Meat Chicken Nuggets "
              footnoteColor="#006B2D"
              productID="00053400000293"
            >
              <img
                src="/raised-and-rooted-buffalo-style-plant-based-bites-708x721.png"
                alt="Raised And Rooted Buffalo Style Plant Based Bites"
              />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-buffalo-style-plant-based-bites-board-708x721.png"
              altText="Buffalo Style Plant Based Bites"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Our Raised & Rooted™ Buffalo Style Plant Based Bites are packed with bold buffalo taste—made only from lean, clean plant based protein."
            >
              <h4>NUTRITION</h4>
              <p>
                8 grams of protein and 4 grams of fiber per serving, 33% less
                saturated fat*, and soy free.
              </p>
              <p>*Than USDA Data for White Meat Chicken Nuggets</p>
              <h4>TASTE</h4>
              <p>
                With a tangy, spicy kick in every bite, this tender yet savory
                plant based protein is sauced and ready to be enjoyed. Serve
                them as an appetizer or as a tasty bite-sized dinner.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <p>
                    Keep frozen until ready to use. Appliances vary. Heating
                    times approximate.
                  </p>
                  <br></br>

                  <b>COVENTIONAL OVEN</b>
                  <p>1. Preheat oven to 375°F</p>
                  <p>
                    2. Arrange bites on a baking sheeting about 1-inch apart.
                  </p>
                  <p>3. Bake for 18-20 minutes or until hot.</p>

                  <b>MICROWAVE</b>
                  <p>1. Arrange 3 bites on a microwave-safe plate.</p>
                  <p>2. Microwave on High for 55-65 seconds or until hot.</p>
                  <p>3. Let stand for 1 minute, before serving.</p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>2.5 Servings Per Container</p>
                  <p>Serving Size 3 pieces (84g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 200</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 11g</td>
                      <td>14%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 7g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 3g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 820mg</td>
                      <td>36%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 16g</td>
                      <td>6%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 3g</td>
                      <td>11%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 2g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 1g Added Sugars</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Protein 8g</td>
                      <td>13%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0.3mcg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Calcium 140mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Iron 1.8mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 220mg</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *The % Daily Value tells you how much a nutrient in a
                        serving of food contributes to a daily diet. 2,000
                        calories a day is used for general nutrition advice.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                  VEGAN FIBERS (WATER, PEA PROTEIN ISOLATE, CANOLA OIL, SODIUM
                  ALGINATE, CITRUS FIBER, CALCIUM CHLORIDE), WATER, HOT SAUCE
                  (AGED CAYENNE RED PEPPERS, VINEGAR, WATER, SALT, GARLIC
                  POWDER, XANTHAN GUM), ENRICHED WHEAT FLOUR (ENRICHED WITH
                  NIACIN, REDUCED IRON, THIAMINE MONONITRATE, RIBOFLAVIN, FOLIC
                  ACID), WHEAT FLOUR, SOYBEAN OIL, PEA PROTEIN CONCENTRATE,
                  VITAL WHEAT GLUTEN, CONTAINS 2% OR LESS: CORN STARCH,
                  DEXTROSE, EXTRACTIVES OF PAPRIKA, FLAXSEED, GARLIC POWDER, HOT
                  SAUCE (CAYENNE PEPPER, DISTILLED VINEGAR, SALT, GARLIC
                  POWDER), LEAVENING (SODIUM ACID PYROPHOSPHATE, SODIUM
                  BICARBONATE), MALTODEXTRIN, METHYLCELLULOSE, MODIFIED CORN
                  STARCH, MODIFIED WHEAT STARCH, NATURAL FLAVOR, DARK MEAT TYPE
                  [MALTODEXTRIN (FROM CORN), SALT, NATURAL FLAVOR, YEAST
                  EXTRACT, PALM OIL, CARROT FIBER, CITRIC ACID, MEDIUM CHAIN
                  TRIGLYCERIDES, ONION POWDER, ONION JUICE CONCENTRATE, TORULA
                  YEAST, CANOLA OIL, SPICE], NATURAL FLAVORS, OAT FIBER, ONION
                  POWDER, PALM OIL, ROSEMARY EXTRACT, SALT, SEA SALT, SPICES,
                  SUNFLOWER OIL, TAPIOCA MALTODEXTRIN, VEGETABLE SHORTENING
                  (SUNFLOWER OIL), VINEGAR, WHEAT GLUTEN, XANTHAN GUM, YEAST,
                  YEAST EXTRACT.
                </p>
                <p>CONTAINS WHEAT</p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000293" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
